import React from "react"
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import styled from '@emotion/styled';

import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"

const Image = styled(Img)`
  margin: 60px -60px;
  width: calc(100% + 120px);

  @media(max-width: 630px) {
    width: calc(100% + 40px);
    margin: 60px -20px;
  }
`

const EmployerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      kanban: file(relativePath: { eq: "kanban.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designs: file(relativePath: { eq: "designs.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pr: file(relativePath: { eq: "pr.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eslint: file(relativePath: { eq: "eslint.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Your candidate has an ooloo.io app on their resume? This is what they learned" />

      <Container>
        <h1>Your candidate has an ooloo.io app on their resume?</h1>
        <h2>This is what they learned</h2>
        <p>The goal of our course is to teach developers how to work in a professional environment and prepare them for working in a real-world company.</p>
        <p>A typical career path of many React developers independent of their education looks like this:</p>
        <ul>
          <li>Take online courses to learn the React ecosystem</li>
          <li>Follow tutorials</li>
          <li>Build small apps like a Todo or Weather apps</li>
          <li>Apply for jobs</li>
        </ul>
        <p>When you see a resume like this you might wonder if this candidate is capable of working on a production application without too much hand-holding. <b>And you're right!</b></p>

        <h2>More than just coding in React</h2>
        <p>Working as a professional software dev requires more than just being able to code in React.</p>
        <ul>
          <li><Link to="/employers/#independence">Implementing features independently</Link></li>
          <li><Link to="/employers/#workflow">Structured working in an Agile workflow</Link></li>
          <li><Link to="/employers/#designs">Turning a design into working code</Link></li>
          <li><Link to="/employers/#git">Working with Git, branches, pull requests, and code reviews</Link></li>
          <li><Link to="/employers/#team-rules">Applying the common rules of a team</Link></li>
        </ul>

        <h2 id="independence">Working independently, no hand-holding</h2>
        <p>Our course is not like a usual tutorial. Our developers don't just watch someone else code and try to follow along.</p>
        <p>They get a set of tasks and designs. The implementation has to be done on their own.</p>
        <p>They can request code reviews by an experienced React developer though. This helps them to catch up on best practices and improve their skills.</p>
        <p>Just like in a normal job.</p>

        <h2 id="workflow">Kanban and ClickUp</h2>
        <p>Project management is an integral part of any software company. That's why we use ClickUp to teach our developers Kanban as a modern agile workflow.</p>
        <Image fluid={data.kanban.childImageSharp.fluid} />
        <p>All tasks are written as user stories with detailed acceptance criteria.</p>
        <p>Many developers are not used to thinking of features in the scope of tasks at the beginning. They touch multiple features within one pull request.</p>
        <p>With well-defined tasks and code reviews, they learn a structured way of feature-driven software development.</p>

        <h2 id="designs">Pixel-perfect designs and Zeplin</h2>
        <p>One of the main tasks of every frontend developer is translating designs into functioning code. We provide professional designs made with Sketch that are hosted on Zeplin.</p>
        <Image fluid={data.designs.childImageSharp.fluid} />
        <p>Creating pixel-perfect designs with CSS is another story. Our developers learn relevant techniques that make them a valuable asset for any design-focused product.</p>

        <h2 id="git">PRs and GitHub</h2>
        <p>Our developers learn a professional workflow with GitHub, pull requests and code reviews.</p>
        <Image fluid={data.pr.childImageSharp.fluid} />
        <p>Most developers who lack professional team experience also lack practical knowledge of working with Git. But in a team setting, you can't work on the master branch.</p>
        <p>Our developers learn how to work with Git and pull requests. Branching, squash merging, and rebasing aren't new to them.</p>

        <h2 id="team-rules">Team rules and ESLint</h2>
        <p>Every team has its own rules. And every developer needs to play by them.</p>
        <Image fluid={data.eslint.childImageSharp.fluid} />
        <p>We use strict ESLint rules that our developers have to stick to. Our CI pipelines ensure that the code format is valid.</p>
      </Container>
    </Layout>
  )
}

export default EmployerPage

